import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Idle } from '@ng-idle/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-idle-msg',
  templateUrl: './idle-msg.component.html',
  styleUrls: ['./idle-msg.component.scss'],
})
export class IdleMsgComponent implements OnInit {
  idleState = 'No longer idle.';
  // showMsg = true;
  constructor(
    private router: Router,
    private authService: AuthService,
    private idle: Idle,
    private thisDialogRef: MatDialogRef<IdleMsgComponent>
  ) {
    idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleState = 'Time out in ' + countdown + ' seconds!';
      // if (countdown < 1) {
      //   console.log('hello')
      //   this.thisDialogRef.close('Cancel');
      // }
      // console.log(countdown);
    });

    idle.onIdleEnd.subscribe(() => {
      this.thisDialogRef.close('Cancel');
      this.idle.watch();
      this.idleState = 'No longer idle.';
      // console.log('no idle');
    });
  }

  ngOnInit() {}
  stay() {
    // this.showMsg = false;
    // this.childModal.hide();
    // console.log('in');
    this.thisDialogRef.close('Cancel');

    //   this.reset();
  }

  logout() {
    // console.log('out');
    // this.showMsg = false;
    //   // this.childModal.hide();
    this.authService.logout();
    this.thisDialogRef.close('Cancel');
    this.router.navigate(['/']);
  }
}
