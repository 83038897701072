import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { LoadingBarModule, LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HomeComponent } from './components/home/home.component';s
import { ApiService } from './services/api.service';
import {
  NavbarComponent,
  OpenConfirmmDialog,
} from './components/navbar/navbar.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
// import { FooterComponent } from './components/footer/footer.component';
// import { ApitestComponent } from './components/apitest/apitest.component';
import { UserregisterComponent } from './components/userregister/userregister.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { AuthService } from './services/auth.service';
import { ValidationService } from './services/validation.service';
import { AuthGuard } from './services/auth.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { UserService } from './services/user.service';
import { CommonService } from './services/common.service';
import { MatInputModule } from '@angular/material/input';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
// import { OpenConfirmDialog } from './components/tasks/mytasks/mytasks.component';
// import { OpenConfirmDialog2 } from './components/tasks/historical/historical.component';
import { MatTableModule } from '@angular/material/table';
import { TranslateService } from './services/translate.service';
import { TranslatePipe } from './pipes/translate.pipe';
import { ProfileComponent } from './components/profile/profile.component';
import { IdleMsgComponent } from './components/idle-msg/idle-msg.component';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { LoginWrapperComponent } from './components/userlogin/login-wrapper.component';
import { UserloginComponent } from './components/userlogin/user-login.component';
import { HelpComponent } from './components/help/help.component';
import { SharedModule } from './shared.module';
import { DisclaComponent } from './components/discla/discla.component';
import { PrivComponent } from './components/priv/priv.component';
// import { UserloginComponent } from './components/userlogin/userlogin.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'tasks',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      { path: 'login', component: UserloginComponent },
      { path: 'register', component: UserregisterComponent },
      // { path: 'login', loadChildren: () => import('./components/userlogin/userlogin.module').then(m => m.UserloginModule) },
      { path: 'aboutus', component: AboutusComponent },
      // { path: 'aboutus', component: AboutusComponent, canActivate: [AuthGuard] },
      {
        path: 'admin',
        loadChildren: () =>
          import('./components/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'passwordreset/:',
        loadChildren: () =>
          import('./components/password-reset/password-reset.module').then(
            (m) => m.PasswordResetModule
          ),
      },
      // { path: 'taskapi', component: ApitestComponent, canActivate: [AuthGuard] },
      {
        path: 'support',
        loadChildren: () =>
          import('./components/support/support.module').then(
            (m) => m.SupportModule
          ),
      },
      { path: 'help', component: HelpComponent },
      { path: 'discla', component: DisclaComponent },
      { path: 'priv', component: PrivComponent },
      // { path: 'collections', loadChildren: () => import('./components/collections/collections.module').then(m => m.CollectionsModule) },
      // { path: 'historical', loadChildren: () => import('./components/tasks/historical/historical.module').then(m => m.HistoricalModule) },
      // {
      //   path: 'newtaskaoi',
      //   loadChildren: () => import('./components/appcesium/appcesium.module').then(m => m.AppcesiumModule)
      // },
      // {
      //   path: 'newtasks',
      //   loadChildren: () => import('./components/tasks/new-tasks/new-tasks.module').then(m => m.NewTasksModule)
      // },
      {
        path: 'profile',
        loadChildren: () =>
          import('./components/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('./components/tasks/tasks.module').then((m) => m.TasksModule),
      },
      // {
      //   path: 'apitest',
      //   loadChildren: () => import('./components/apitest/apitest.module').then(m => m.ApiTestModule)
      // },
    ],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    IdleMsgComponent,
    AboutusComponent,
    PrivComponent,
    DisclaComponent,
    // ApitestComponent,
    // FooterComponent,
    LoginWrapperComponent,
    // UserloginComponent1,
    UserloginComponent,
    UserregisterComponent,
    LayoutComponent,
    NavbarComponent,
    OpenConfirmmDialog,
    // OpenConfirmDialog,
    // OpenConfirmDialog2,
    TranslatePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    JwtModule,
    MatBadgeModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    ReactiveFormsModule,
    // AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    // RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, { useHash: true }),
    // RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'}),
    SharedModule,
  ],
  exports: [RouterModule],
  providers: [
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 3 } },
    ApiService,
    TranslateService,
    CommonService,
    ValidationService,
    UserService,
    AuthGuard,
    AuthService,
    JwtHelperService,
  ],
  // bootstrap: [AppComponent]
  bootstrap: [AppComponent],
})
export class AppModule {}
