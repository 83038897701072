<!-- <div class="detailBtn" (click)='showDetail = !showDetail; scrollWin()'>
      <button style="background: black;">
        <mat-icon style="color: white;" *ngIf='!showDetail'>arrow_downward</mat-icon>
        <mat-icon style="color: white;" *ngIf='showDetail'>arrow_upward</mat-icon>
      </button>
    </div> -->

<div class="row g-0 footer">
  <div class="d-flex justify-content-between" style="padding: 3px 30px">
    <div class="d-flex justify-content-start align-self-center">
      <div>
        <a title="Home" class="a" (click)="gotoRoute('tasks/mytasks')">Home</a
        ><span class="mlr3">|</span>
      </div>
      <div>
        <a title="" class="a" (click)="gotoRoute('aboutus')">About Us</a
        ><span class="mlr3">|</span>
      </div>
      <div>
        <a title="" class="a" (click)="gotoRoute('support')">Contact Us</a
        ><span class="mlr3">|</span>
      </div>
      <div>
        <a title="" class="a" (click)="gotoRoute('discla')">Disclaimer</a
        ><span class="mlr3">|</span>
      </div>
      <div>
        <a title="" class="a" (click)="gotoRoute('priv')">Privacy Policy</a>
      </div>
    </div>
    <div class="d-flex justify-content-end align-self-center">
      <div title="Home" routerLink="tasks/mytasks">
        <span *ngIf="curYear > 2021"
          ><span>© 2021-{{ curYearStr }} </span
          ><a (click)="gotoRoute('tasks/mytasks')"
            ><img style="width: 120px" src="assets/images/logo.png" />{{
              title
            }}</a
          ></span
        >
      </div>
      <!-- <span *ngIf='curYear===2022'><span style="font-size: 15px;">© 2022 </span><img style="width: 110px;" src="assets/images/logo.png">{{title}}</span> -->
    </div>
  </div>
</div>
