import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discla',
  templateUrl: './discla.component.html',
  styleUrls: ['./discla.component.scss'],
})
export class DisclaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
