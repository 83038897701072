import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import {Observable, BehaviorSubject} from 'rxjs';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';

import { UserModel } from '../models/api.model';
import { CommonService } from './common.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService {
  server = environment.usersServer;
  url: string;
  public users: any = [];
  public sensors: any = [];
  private sensorsBS = new BehaviorSubject<any>(this.sensors);
  castSensor = this.sensorsBS.asObservable();
  // private usersBS = new BehaviorSubject<UserModel>(this.users);
  // castCrt = this.usersBS.asObservable();

  constructor(private http: HttpClient, private commonSer: CommonService) {
    this.url = this.server;
    // this.getUsers().subscribe((users: UserModel[]) => {
    //   this.users = users; this.url = this.commonSer.baseAppUrl;
    // });
  }

  getUsers() {
    const req = this.http.get(`${this.url}`);
    return req;
  }
  addFeedback(feedbackBody: any) {
    return this.http.post(`${this.url}/admin/addfb`, feedbackBody);
  }
  // addUser(user: UserModel) {
  //   this.http.post(`${this.url}/admin/adduser`, user)
  //       .subscribe(res => console.log('New user registered'));
  // }
  genOTP(phone: string) {
    return this.http.get(`${this.url}/admin/genOTP/${phone}`);
  }

  addUser(formData: FormData): any {
    // console.log(user.photo);
    return this.http.post(`${this.url}/admin/adduser`, formData);
    // .subscribe(
    //   res => {
    //     return (res);
    //     // console.log(res);
    //   },
    //   err => console.log(err));
  }
  sendMail(mailObj: any) {
    // console.log(mailObj)
    return this.http.post(`${this.url}/admin/sendmail`, mailObj);
  }
  findWithId(route: any, id: any) {
    return this.http.get(`${this.url}/${route}/find/${id}`);
  }
  chkRegisteredUser(email: string) {
    // email = email.toLowerCase();
    const req = this.http.get(`${this.url}/admin/findUser/${email}/`);
    // console.log(req);
    return req;
  }

  // change password
  changePswrd(id: string, pswrdObj: any) {
    return this.http.post(`${this.url}/admin/changePassword/${id}`, pswrdObj);
    // this.http.post(`${this.url}/admin/changePassword/${id}`, pswrdObj)
    //     .subscribe(
    //       res => console.log(res),
    //       err => console.log(err));
  }
  // change password
  resetPswrd(token: string, pswrdObj: any) {
    return this.http.post(`${this.url}/admin/resetPassword/${token}`, pswrdObj);
  }

  // get payment option detail
  getSensors(subscrptn: string) {
    return this.http.get(`${this.url}/sensors/${subscrptn}`);
    //   .subscribe(
    //     res => console.log(res),
    //     err => console.log(err));
  }
  // get payment option detail
  getPayDetail(id: any) {
    return this.http.get(`${this.url}/vendor/getPayDetail/${id}`);
  }

  // add payment detail
  addPayDetail(payFormData: FormData) {
    // console.log(payFormData)
    this.http.post(`${this.url}/vendor/addPayDetail/`, payFormData).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  }
  // add payment detail
  updatePayDetail(payFormData: FormData) {
    // console.log(payFormData)
    this.http
      .post(`${this.url}/vendor/updatePayDetail/`, payFormData)
      .subscribe(
        (res) => console.log(res),
        (err) => console.log(err)
      );
  }
  // change address
  changeAddress(id: string, address: any) {
    this.http
      .post(`${this.url}/admin/changeAddress/${id}`, address)
      .subscribe();
  }
  //         res => console.log(res),
  //         err => console.log(err));
  // }
  // change photo
  // console.log(user.photo);
  // return this.http.post(`${this.url}/admin/adduser`, formData);
  changePhoto(id: string, formData: FormData): any {
    this.http
      .post(`${this.url}/admin/changePhoto/${id}`, formData)
      .subscribe((res: any) => {
        // console.log(res)
      });
  }
  // modify user
  modifyUser(id: string, newVals: any) {
    this.http
      .post(`${this.url}/admin/modify/${id}`, newVals)
      .subscribe((res) => console.log('User modified'));
  }
  emailValidator = (control: { value: string }) => {
    let val: any;
    let t1 = 0;
    this.chkRegisteredUser(control.value).subscribe((res: any) => {
      if (res !== null) {
        // return { validEmail: true };
        val = { validEmail: true };
      } else {
        // return null;
        val = null;
      }
      t1 = 1;
      // console.log('1. res', control.value, res);
      // return this.val;
    });
    // console.log('2. val', control.value, val);
    return val;
  };
  getUserWithPhonenPswrd(phone: string, password: string) {
    const req = this.http.get(
      `${this.url}/admin/findphone/${phone}/${password}`
    );
    return req;
  }
  getUserWithEmailnPswrd(loginparams: string) {
    // console.log(encodeURIComponent(password))
    // const loginparams = JSON.stringify({email: email, password: encodeURIComponent(password)});
    // console.log(loginparams);
    const req = this.http.get(`${this.url}/admin/finduser/${loginparams}`);
    return req;
  }

  refreshToken(refreshToken: any) {
    const rt = new FormData();
    rt.append('refreshToken', refreshToken);
    const req = this.http
      // .post(`${this.url}/admin/refresh`, rt);
      .get(`${this.url}/admin/refresh/${refreshToken}`);
    return req;
  }
  // public get loggedIn(): boolean {
  //   return (localStorage.getItem('appaccess_token') !== null);
  // }

  // addUser(user: UserModel) {
  //   this.http.post(`${this.url}/admin/add`, user)
  //       .subscribe(res => console.log('New user registered'));
  // }

  editUser(id: any) {
    return this.http.get(`${this.url}/edit/${id}`);
  }

  updateField(id: any, Field: any, Val: any) {
    const obj = { field: Field, val: Val };
    return this.http.post(`${this.url}/admin/updateField/${id}`, obj);
    // .subscribe(res => console.log('Update Complete'));
  }
  forgotPassword(userEmail: string) {
    // console.log(this.url); return
    const obj = { email: userEmail };
    return this.http.post(`${this.url}/admin/forgot`, obj);
    // .subscribe(res => console.log('Update Complete'));
  }

  /**
   * update user account blance after successfully payment
   * @returns response object
   */
   updateAccountBalance(user:any, amount: number) {
    const obj = { 
      user: user,
      amount: amount
    };
    const res = this.http.post(`${this.url}/admin/updateAccountBalance`, obj);
    return res;
  }

  deleteUser(id: any) {
    return this.http.get(`${this.url}/admin/delete/${id}`);
  }

  find(id: string): UserModel {
    return this.users[this.getSelectedIndex(id)];
  }

  private getSelectedIndex(id: string) {
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i]._id === id) {
        return i;
      }
    }
    return -1;
  }
}
