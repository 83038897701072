export const config = {
  currencies: [
    { currency: "USD", sign: "$" },
    { currency: "AUD", sign: "aud" },
  ],
  ms: {
    // usersServer: 'http://localhost:1117',
    // mailServer: 'http://localhost:2223',
    eeTaskServer: "/api/tasking",

    usersServer: "/api/user",
    mailServer: "/api/email",
    // eeTaskServer: 'https://sattaskapi.freegyanhub.com'
    // eePGUrl: 'http://localhost:1116',
    // eePGUrl: 'https://eepgservice.freegyanhub.com',
  },
  staging: {
    eeTaskServer: "https://eartheye-eetaskapi-dev.xminds.in",
    usersServer: "https://eartheye-eeusersapi-dev.xminds.in",
    mailServer: "https://eartheye-email-dev.xminds.in",
  },
};
