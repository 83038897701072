import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-priv',
  templateUrl: './priv.component.html',
  styleUrls: ['./priv.component.scss'],
})
export class PrivComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
