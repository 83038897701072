import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CesiumComponent } from './components/shared/cesium/cesium.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LeftpanComponent } from './components/shared/leftpan/leftpan.component';
import { SnackbarComponent } from './components/shared/snackbar/snackbar.component';
// import { FormsModule } from '@angular/forms';
// import { AppcesiumComponent } from './components/appcesium/appcesium.component';

@NgModule({
  declarations: [
    CesiumComponent,
    LeftpanComponent,
    FooterComponent,
    SnackbarComponent,
  ],
  imports: [CommonModule, MatCardModule, MatSnackBarModule, MatTooltipModule],
  exports: [
    CommonModule,
    LeftpanComponent,
    CesiumComponent,
    FooterComponent,
    SnackbarComponent,
  ],
})
export class SharedModule {}
