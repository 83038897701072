import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Lang } from '../../assets/i18n/lang';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  data: any = {};
  private lng: any = [];
  private lngBS = new BehaviorSubject<any>(this.lng);
  castLng = this.lngBS.asObservable();
  constructor(private http: HttpClient, private titleService: Title) {}

  async getLang() {
    let curLang = JSON.parse(localStorage.getItem('lang')!);
    curLang = curLang || 'en';

    if (curLang) {
      this.use(curLang);
    }

    await this.lngBS.next(this.lng);
    return curLang;
  }

  async use(lang: string): Promise<{}> {
    await this.lngBS.next(lang);
    return new Promise<{}>((resolve, reject) => {
      localStorage.setItem('lang', JSON.stringify(lang));
      const langPath = `assets/i18n/${lang || Lang[0].value}.json`;
      this.http.get<{}>(langPath).subscribe(
        (translation) => {
          this.data = Object.assign({}, translation || {});
          this.titleService.setTitle(this.data.TITLE);
          resolve(this.data);
        },
        (error) => {
          this.data = {};
          console.log('Error');
          resolve(this.data);
        }
      );
    });
  }
}
