import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from 'src/app/services/translate.service';
import { UserloginComponent } from '../userlogin/user-login.component';
import { UserregisterComponent } from '../userregister/userregister.component';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  subscribe: Subscription;
  currentUser: any;
  loggedinuser = '';
  userPhoto: any;
  mnuClose: boolean = true;
  currency = environment.payCurrency;
  currencySign = environment.payCurrencySign;
  CARTDATA = [];
  // cartSummary = false;
  NAVLIST = ([] = this.apiService.NAVLIST);
  NAVLISTRT = ([] = this.apiService.NAVLISTRT);
  MYSUBMENU = ([] = this.apiService.MYSUBMENU);

  position: TooltipPosition = 'left';
  message: string = 'Here is the tooltip';
  disabled = false;
  showDelay = 0;
  hideDelay = 100;
  showExtraClass = true;
  constructor(
    private router: Router,
    private ngZone: NgZone,
    public translate: TranslateService,
    private apiService: ApiService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser();
    this.authService.castCurrentUser.subscribe((res: any) => {
      this.currentUser = res;
      if (this.currentUser)
        if (this.currentUser.email) {
          this.loggedinuser =
            this.currentUser.displayname || this.currentUser.name;
          if (this.currentUser.photo) {
            this.userPhoto =
              this.apiService.usersImgUrl + this.currentUser.photo ||
              'assets/images/avatar.svg';
          } else {
            this.userPhoto = 'assets/images/avatar.svg';
          }
          this.apiService.cartdata(this.currentUser.id);
          this.subscribe = this.apiService.castCartData.subscribe(
            (res: any) => {
              this.CARTDATA = res;
              this.CARTDATA.forEach((item, index) => {
                let tt = [];
                item.cartdata.forEach((oprtr, index) => {
                  tt.push(
                    `${index + 1}. ${oprtr.name} ${oprtr.sensor} (${
                      oprtr.resolution
                    }m): ${this.currencySign}${oprtr.cost.toFixed(2)}`
                  );
                });
                this.CARTDATA[index].tooltip = tt.join('\n ');
                // console.log(this.CARTDATA[index].tooltip);
              });
            }
          );
          this.apiService.orderdata(this.currentUser.id, { status: 'All' });
          this.subscribe = this.apiService.castOrderData.subscribe(
            (res: any) => {
              // this.CARTDATA = res;
            }
          );
          // this.subscribe = this.apiService.castCartData.subscribe((res: any) => this.ngZone.run(() => {
          //   this.CARTDATA = res;
          // }));
        }
      // console.log(this.currentUser)
    });
  }

  showCartSummary(showme: boolean) {
    // console.log(showme)
  }
  go2Route(id: any) {
    this.NAVLISTRT.forEach((item, index) => {
      if (id === index) {
        this.NAVLISTRT[index].active = true;
      } else {
        this.NAVLISTRT[index].active = false;
      }
    });
    // console.log(this.NAVLISTRT[id].url);
    this.router.navigate([this.NAVLISTRT[id].url]);
  }

  toggleMenu() {
    var x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
  login() {
    this.router.navigate(['/login']);
    // this.dialog.open(UserloginComponent);
  }
  register() {
    this.dialog.open(UserregisterComponent);
  }
  logout() {
    const dialogRef = this.dialog.open(OpenConfirmmDialog, {
      width: '250px',
      data: { name: this.currentUser.displayname, confirm: true },
    });

    dialogRef.afterClosed().subscribe((response) => {
      // console.log('The dialog was closed', response);
      if (response) {
        this.authService.logout();
      }
    });
    // if (confirm('Click Ok to logout or cancel to return back.')) {
    //   this.userPhoto = 'assets/images/avatar.svg';
    // }
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
})
export class OpenConfirmmDialog {
  constructor(
    public dialogRef: MatDialogRef<OpenConfirmmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
