import { config } from '../assets/config/config';

export const environment = {
  production: true,
  payCurrency: config.currencies[0].currency,
  payCurrencySign: config.currencies[0].sign,
  usersServer: config.staging.usersServer,
  mailServer: config.staging.mailServer,
  eeTaskServer: config.staging.eeTaskServer,
  // eePGUrl: config.ms.eePGUrl,
};
