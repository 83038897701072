import { Injectable } from '@angular/core';
// import { Validators, FormGroup, FormControl } from '@angular/forms';
// import { ProductService } from '../services/product.service';
import { FormControl } from '@angular/forms';
// import { UserService } from './user.service';
// import { UserModel } from '../models/product.model';

@Injectable()
export class ValidationService {
  val: any;
  constructor() {}
  // tslint:disable-next-line: variable-name
  public account_validation_messages = {
    name: [
      { type: 'required', message: 'Your Full Name is required' },
      { type: 'minlength', message: 'At least 5 characters long' },
      { type: 'maxlength', message: 'Maximum 50 characters long' },
      { type: 'pattern', message: 'Invalid format' },
      //{ type: 'validUsername', message: 'Your username has already been taken' }
    ],
    displayname: [
      { type: 'required', message: 'Display Name is required' },
      { type: 'minlength', message: 'At least 4 characters long' },
      { type: 'maxlength', message: 'Maximum 15 characters long' },
      { type: 'pattern', message: 'Only numbers and letters' },
    ],
    company: [
      { type: 'required', message: 'Company Name is required' },
      { type: 'maxlength', message: 'Maximum 80 characters long' },
      { type: 'pattern', message: 'Invalid format' },
      {
        type: 'validCompanyname',
        message: 'Your Company has already been registered',
      },
    ],
    phone: [
      { type: 'required', message: 'Valid phone number is required' },
      { type: 'minlength', message: 'At least 10 numeral characters long' },
      { type: 'maxlength', message: 'Maximum 10 numeral characters long' },
      { type: 'pattern', message: 'Number is not valid' },
    ],
    email: [
      { type: 'required', message: 'Valid email is required' },
      { type: 'pattern', message: 'Email is not valid' },
      // { type: 'validEmail', message: 'Your email has already been taken' }
    ],
    oldpassword: [
      { type: 'required', message: 'Old password is required' },
      { type: 'validPassword', message: 'Old password is wrong' },
    ],
    password: [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Minimum 5 letters' },
      {
        type: 'pattern',
        message: 'At least one uppercase, one lowercase, and one number',
      },
    ],
    confirmpassword: [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'areEqual', message: 'Password mismatch' },
    ],
    terms: [
      { type: 'pattern', message: 'You must accept terms and conditions' },
    ],
    project: [
      { type: 'required', message: 'Project Name is required' },
      { type: 'minlength', message: 'At least 4 characters long' },
      { type: 'maxlength', message: 'Maximum 25 characters long' },
      { type: 'pattern', message: 'Only letters and numbers' },
    ],
    taskname: [
      { type: 'required', message: 'Task Name is required' },
      { type: 'minlength', message: 'At least 5 characters long' },
      { type: 'maxlength', message: 'Maximum 25 characters long' },
      { type: 'pattern', message: 'Only letters and numbers' },
    ],
  };

  static creditCardValidator(control: any) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    // tslint:disable-next-line: max-line-length
    if (
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  // emailValidator1 = (control: any) => {
  //   // const t1 = (this.ps.toCheck(control.value));
  //   // this.ps.chkRegisteredUser(control.value).subscribe(res => {
  //   //   console.log(2, res);
  //   //   return res ? { vaildEmail: true } : null;
  //   // });
  //   // console.log(t1);
  //   // console.log(2);
  //   return { vaildEmail: true };
  // }

  emailValidator = (control: any) => {
    //   console.log('1. res');
    //   this.ps.chkRegisteredUser(control.value).subscribe((res: any) => {
    //     if (res !== null) {
    //   //     // return { validEmail: true };
    //       this.val = { validEmail: true };
    //     } else {
    //   //     // return null;
    //       this.val = null;
    //     }
    //   //   t1 = 1;
    //   //   console.log('1. res', control.value, res);
    //   //   // return this.val;
    //   });
    //   // if (t1 > -1) {
    //     // this.tmp(control.value);
    //     // console.log('2. val', control.value, this.val);
    //     return this.val;
    //   // }
  };

  // tmp(val: string): any {
  //   this.ps.chkRegisteredUser(val).subscribe((res: any) => {
  //     // if (res !== null) {
  //     //   this.val = { validEmail: true };
  //     // } else {
  //     //   this.val = null;
  //     // }
  //     console.log('fun1. res', res);
  //     return res;
  //   });
  //   // console.log('0. res');
  // }

  userNameValidator(control: any) {
    const name = control.value.trim();
    if (name.match(/^[A-Z a-z]+$/)) {
      return null;
    } else {
      return { invalidUserName: true };
    }
  }

  // tslint:disable-next-line: member-ordering
  confirmpswrdValidator(c: any) {
    // let that = this;
    // return (c: FormControl) =>
    // {
    //     return (c.value == c.parent.value.password) ? null : {invalidConfirmpassword: true};
    // }

    return { invalidConfirmpassword: true };
    // let retVal: any;
    // if (control.parent !== undefined) {
    //   if (control.parent.value.password === control.value) {
    //     retVal = null;
    //   } else {
    //     retVal = { invalidConfirmpassword: true };
    //   }
    // }
    // return retVal;
  }
}
