import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  Inject,
} from '@angular/core';
import * as Cesium from 'cesium';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Data, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ValidationService } from 'src/app/services/validation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
var turf = require('@turf/turf');

const cesiumContainer = document.createElement('div');
cesiumContainer.id = 'cesiumGlobeContainer';
document.getElementsByTagName('body')[0].appendChild(cesiumContainer);

@Component({
  selector: 'app-cesium',
  templateUrl: './cesium.component.html',
  styleUrls: ['./cesium.component.scss'],
})
export class CesiumComponent implements OnInit {
  @Input() target: any = { geoJSON: '', area: 0 };
  mapSnap: any;
  defaultLL: any[] = [82.9739, 25.3176];
  roll: any = 0;
  viewer = null;
  scene = null;
  longLatStr: string = '';
  latlonOverlay = document.createElement('div');
  taskTarget: any;
  taskid: any;
  taskname: any;
  coordinates: string;

  title = 'Confirmation';
  cancelBtn = 'Cancel Task';
  btnParams = 'CANCEL';
  successMsg: any;
  currentUser: any;
  taskMap: any;

  constructor(
    private vs: ValidationService,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CesiumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {
    this.taskid = data.taskid;
    this.taskname = data.taskname;
    console.log(this.data);
    this.taskTarget = JSON.parse(this.data.target);
    // this.coordinates = this.taskTarget.coordinates;
    if (this.taskTarget)
      if (this.taskTarget.type === 'Polygon') {
        this.coordinates = this.taskTarget.coordinates[0]
          .toString()
          .split(',')
          .map(Number);
      } else {
        this.coordinates = this.taskTarget.coordinates;
      }
    // console.log(this.taskid, this.taskname, this.coordinates);
  }

  ngOnInit(): void {
    var west = 40.0;
    var south = -20.0;
    var east = 120.0;
    var north = 70.0;

    var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
    Cesium.Ion.defaultAccessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMjE0NmRmNS1jODRhLTRhM2UtOGVhNS1jZWQ1YTU5M2JlMzYiLCJpZCI6NzEyMTgsImlhdCI6MTYzNDk5MjM1Nn0.duiyZyWIzedxFMPIDVe7GPt_aEooV-zHw6j49wAjP1U';
    Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;

    const imageryViewModels = [];
    const terrainViewModels = [];
    //Sentinel data
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Sentinel-2',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/sentinel-2.png'
        ),
        tooltip: 'Sentinel-2 cloudless',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3954 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Road',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingRoads.png'
        ),
        tooltip: 'Bing Maps Road',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 4 });
        },
      })
    );
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Blue Marble',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/blueMarble.png'
        ),
        tooltip: 'Blue Marble Next Generation July, 2004 imagery from NASA.',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3845 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'OpenStreetMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png'
        ),
        tooltip: 'Open Street Map.',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          });
        },
      })
    );
    const worldTerrain = Cesium.createWorldTerrain({ requestWaterMask: false });
    terrainViewModels.push(worldTerrain);
    // var credit = new Cesium.Credit("<a style='padding-left: 3px' class='credit' target='_blank' href='https://rk.freegyanhub.com'><img width='18px' src='assets/images/rklogo.svg' alt='RK'></a>");
    this.viewer = new Cesium.Viewer('cesiumContainer', {
      imageryProviderViewModels: imageryViewModels,
      terrainProviderViewModels: [],
      // imageryProvider: baseLayer,
      // terrainExaggeration: 2.0,
      terrainProvider: worldTerrain,
      baseLayerPicker: false,
      geocoder: false,
      homeButton: true,
      // navigationHelpButton: false,
      fullscreenButton: false,
      selectionIndicator: true,
      shouldAnimate: false,
      animation: false,
      timeline: false,
      // infoBox: true,
      sceneModePicker: false,
      scene3DOnly: true,
    });

    this.showLatLon();

    var target = this.target;

    this.scene = this.viewer.scene;
    if (!this.scene.pickPositionSupported) {
      window.alert('This browser does not support pickPosition.');
    }
    this.plotTarget();
  }

  plotTarget() {
    let target: any,
      viewer = this.viewer;

    if (this.taskTarget)
      if (this.taskTarget.type === 'Polygon') {
        const fpPos = this.taskTarget.coordinates[0]
          .toString()
          .split(',')
          .map(Number);
        // const pts = this.taskTarget.coordinates[0]
        var bbox = turf.bbox(this.taskTarget);
        // var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
        var rectangle = Cesium.Rectangle.fromDegrees(
          bbox[0],
          bbox[1],
          bbox[2],
          bbox[3]
        );
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;
        target = viewer.entities.add({
          name: this.taskname,
          polygon: {
            hierarchy: {
              positions: Cesium.Cartesian3.fromDegreesArray(fpPos),
            },
            fill: false,
            // material : Cesium.Color.BLUE.withAlpha(0.2),
            height: 0,
            outlineColor: Cesium.Color.YELLOW,
            outline: true, // height is required for outline to display
          },
        });
        // viewer.zoomTo(target);
        viewer.flyTo(target, { duration: 3 });
      } else if (this.taskTarget.type === 'Point') {
        const fpPos = this.taskTarget.coordinates;
        const lng = fpPos[0],
          lat = fpPos[1];
        var rectangle = Cesium.Rectangle.fromDegrees(
          lng - 1,
          lat - 1,
          lng + 1,
          lat + 1
        );
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;
        target = viewer.entities.add({
          id: this.taskname,
          position: Cesium.Cartesian3.fromDegrees(lng, lat), //  bbPosition,
          billboard: {
            image: 'assets/images/marker-icon.png',
            show: true, // default
            // pixelOffset: new Cesium.Cartesian2(0, 5), // default: (0, 0)
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
            // scale: 0.75, // default: 1.0
            // color: Cesium.Color.LIME, // default: WHITE
            // rotation: Cesium.Math.PI_OVER_FOUR, // default: 0.0
            alignedAxis: Cesium.Cartesian3.ZERO, // default
            width: 20, // default: undefined
            height: 30, // default: undefined
          },
        });
        viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(lng, lat), // bbPosition,
          billboard: {
            image: 'assets/images/marker-shadow.png',
            pixelOffset: new Cesium.Cartesian2(5, 0), // default: (0, 0)
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
            scale: 0.6,
          },
        });
        this.defaultLL = [lng, lat];
        this.viewer.camera.flyTo(
          {
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 100000.0),
            orientation: {
              heading: Cesium.Math.toRadians(0),
              pitch: Cesium.Math.toRadians(-90.0),
              roll: 0.0,
            },
          },
          { duration: 3 }
        );
      }
    // viewer.zoomTo(target);
  }

  flyHome() {
    // const ellipsoid = new Cesium.Ellipsoid(0,0,0);
    // let result: any;
    // this.viewer.camera.computeViewRectangle(ellipsoid, result)
    console.log(
      this.viewer.camera.heading,
      this.viewer.camera.pitch,
      this.viewer.camera.roll
    );
    this.viewer.camera.flyHome(2); // take 2 seconds
  }

  showLatLon() {
    const viewer = this.viewer;
    const latlonOverlay = this.latlonOverlay;
    const scene = this.viewer.scene;
    const canvas = this.viewer.canvas;
    // this.latlonOverlay.id = 'latlon';
    viewer.container.appendChild(latlonOverlay);
    // latlonOverlay.className = 'llDisp';
    // latlonOverlay.style.display = 'none';
    latlonOverlay.style.position = 'absolute';
    latlonOverlay.style['z-index'] = '10';
    latlonOverlay.style.right = '3px';
    latlonOverlay.style.bottom = '88px';
    // latlonOverlay.style.marginTop = '-30px';
    latlonOverlay.style.height = '30px';

    // latlonOverlay.style.width = 'calc(100% - 37px)';
    latlonOverlay.style.width = 'fit-content';
    latlonOverlay.style['pointer-events'] = 'none';
    latlonOverlay.style.textAlign = 'center';
    latlonOverlay.style.justifyContent = 'center';
    latlonOverlay.style.padding = '5px 4px 0 4px';
    latlonOverlay.style.color = 'rgba(255, 255, 255, 1)';
    // latlonOverlay.style.color = '#026FC2';
    latlonOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
    let mousePosition;
    const handler = new Cesium.ScreenSpaceEventHandler(canvas);

    handler.setInputAction((movement) => {
      mousePosition = movement.endPosition;
      const cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      );
      if (cartesian) {
        // latlonOverlay.style.display = 'block';
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        const longString = Cesium.Math.toDegrees(
          cartographic.longitude
        ).toFixed(2);
        const latString = Cesium.Math.toDegrees(cartographic.latitude).toFixed(
          2
        );
        this.longLatStr =
          'Lon: ' +
          longString +
          '\u00B0' +
          ', \nLat: ' +
          ('   ' + latString).slice(-7) +
          '\u00B0';
        // this.longLatStr = '<caption styel="border-radius:5px; font-size: 16px">' + this.longLatStr + '</caption>';
        latlonOverlay.innerHTML =
          "<div style='padding:0px'>" + this.longLatStr + '</div>';
      } else {
        this.longLatStr = '';
        latlonOverlay.innerHTML = "<div style='padding:0px'>Out of Globe</div>";
        // latlonOverlay.style.display = 'none';
      }
      this.roll = Cesium.Math.toDegrees(this.viewer.camera.heading).toFixed(0);
      if (this.roll > 359.5) this.roll = 0;
      // this.compassOverlay.style.transform = `rotate(${360-this.roll}deg)`;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  onKeyPress(e: any, msg: string) {
    if (e.key === 'Enter') {
      this.onNoClick(msg);
    }
  }
  onNoClick(msg: any): void {
    this.successMsg = '';
    if (msg === 'CANCEL') {
      // const taskid = this.data.taskid;
      const action = this.data.taskaction;
      // this.apiService.taskIdResponse(action, this.data.user, this.taskid).subscribe((res: any) => {
      //   this.successMsg = JSON.stringify(res);
      //   // console.log(JSON.stringify(res))
      // this.cancelBtn = 'Ok';
      // this.btnParams = 'OK';
      // this.title = 'Task cancelled';
      // this.dialogRef.close('Cancelled');
      // });
    } else {
      this.dialogRef.close('Done');
    }
  }
}
