<!-- <div class="modal-content"> -->
<h5 id="dialog-child-name" class="modal-title pull-left">{{ idleState }}</h5>
<div class="btnspacing">
  <button
    class="btn"
    style="width: fit-content"
    (click)="stay()"
    mat-raised-button
  >
    Stay
  </button>
  <button
    class="btn"
    style="width: fit-content"
    (click)="logout()"
    mat-raised-button
  >
    Logout
  </button>
</div>
<!-- </div> -->
<!-- 
<div class="modal-content">
    <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">You Have Been Idle!</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row mrgbtm">
        <div class="col-md-6">
            <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
        </div>
        <div class="col-md-6">
            <button type="button" (click)="stay()" class="btn btn-success">Stay</button>
        </div>
        </div>
    </div>
</div> -->
