import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leftpan',
  templateUrl: './leftpan.component.html',
  styleUrls: ['./leftpan.component.scss'],
})
export class LeftpanComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
