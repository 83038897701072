import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Cesium from 'cesium';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

enableProdMode();

// if (environment.production) {
//   enableProdMode();
// }
window['CESIUM_BASE_URL'] = '/assets/cesium'; // If youre using Cesium version < 1.42.0 add this line
// Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/'); // If youre using Cesium version >= 1.42.0 add this line
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
