// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent {
// }

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './services/auth.service';
import { CommonService } from './services/common.service';
import { TranslateService } from './services/translate.service';
import { IdleMsgComponent } from './components/idle-msg/idle-msg.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  popRef: MatDialogRef<IdleMsgComponent>;
  isShow = false;
  topPosToStartShowing = 150;
  currentUser: any;
  idleState = 'Not started.';
  idleCountDown = 0;
  showMsg = false;
  timedOut = false;
  lastPing?: Date;
  @HostListener('window:resize', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onEvent() {
    // this.chkResize();
    this.chkScroll();
  }
  // chkResize() {
  //   this.columnNum = Math.trunc(window.screen.availWidth/this.tileSize);
  //   console.log(this.columnNum);
  // }
  chkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // app_title = 'himank';
  // name: string;
  // menu: Array<any> = [];
  // private menuBS = new BehaviorSubject<any>(this.menu);
  // castMenu = this.menuBS.asObservable();

  // breadcrumbList: Array<any> = [];
  // constructor(private router: Router, private bcService: BreadcrumbService) {}
  disbledRtClick() {
    // alert('Sorry, right click is not allowed !!');
    return false;
  }

  ngOnInit() {}

  // public modalRef: BsModalRef;

  // @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private idle: Idle,
    private keepAlive: Keepalive,
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService
  ) {
    // constructor(private idle: Idle, private keepalive: Keepalive,
    //             private router: Router, private modalService: BsModalService, private authService: AuthService) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(commonService.IDLETIME);
    //   // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(commonService.TIMEOUTTIME);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log(this.idleState);
      this.reset();
      // if (this.idleCountDown === 0) {
      //   this.showMsg = false;
      // }
    });

    idle.onTimeout.subscribe(() => {
      // this.childModal.hide();
      this.idleState = 'Timed out!';
      this.logout();
      this.timedOut = true;
      this.showMsg = false;
      // console.log(this.idleState);
      this.router.navigate(['login']);
    });

    idle.onIdleStart.subscribe(() => {
      this.popRef = this.dialog.open(IdleMsgComponent);

      this.showMsg = true;
      this.idleState = "You've gone idle!";
      // console.log(this.idleState);
      // this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.showMsg = true;
      this.idleCountDown = countdown;
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log(this.idleState);
      if (countdown < 1) this.popRef.close('Cancel');
    });

    // sets the ping interval
    keepAlive.interval(commonService.KEEPALIVETIME);

    keepAlive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.authService.castCurrentUser.subscribe((res) => {
      this.currentUser = res;
      if (this.currentUser) {
        // this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
        //   if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });

    // this.reset();
  }

  reset() {
    this.idle.watch();
    // this.idleState = 'Started.';
    this.timedOut = false;
    this.idleCountDown = 0;
    // this.popRef.close('Cancel');
    // this.showMsg = false;
  }

  // hideChildModal(): void {
  //   this.showMsg = false;
  //   // this.childModal.hide();
  // }

  stay() {
    this.popRef.close('Cancel');
    // this.childModal.hide();
    // console.log('in');
    this.reset();
    this.showMsg = false;
    console.log('Stay');
    // console.log('out');
  }

  logout() {
    this.popRef.close('Cancel');
    this.authService.logout();
    this.showMsg = false;
    // console.log('Logout');
    // this.childModal.hide();
    this.router.navigate(['login']);
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
