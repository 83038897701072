<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light  navbar navbar-expand-lg navbar-lights menu"> -->

<nav class="navbar navbar-expand-lg navbar-light bg-lights menu">
  <div class="container-fluid">
    <ul class="nav navbar-nav me-auto mb-2 mb-lg-0">
      <span routerLink="tasks/mytasks" class="navbar-brand"
        ><img width="220px" src="assets/images/logo.png"
      /></span>
    </ul>
    <button
      class="navbar-toggler float-xs-right mr-15"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarTogglerDemo02"
      aria-controls="navbarTogglerDemo02"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse navbar-default"
      id="navbarTogglerDemo02"
    >
      <form class="d-flex float-xs-right">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-5">
          <span *ngFor="let item of NAVLISTRT; let i = index" class="nav-item">
            <a
              *ngIf="item.active"
              class="nav-link active"
              aria-current="page"
              (click)="go2Route(i)"
              >{{ item.name }}</a
            >
            <a
              *ngIf="!item.active"
              class="nav-link"
              aria-current="page"
              (click)="go2Route(i)"
              >{{ item.name }}</a
            >
          </span>

          <span *ngIf="currentUser">
            <a
              *ngIf="CARTDATA.length > 0"
              class="dropdown-toggle nav-link"
              matTooltip="{{ CARTDATA.length }} items in cart"
              [matTooltipPosition]="position"
              [matTooltipDisabled]="disabled"
              [matTooltipShowDelay]="showDelay"
              [matTooltipHideDelay]="hideDelay"
              [matTooltipClass]="{ 'blue-tooltip': showExtraClass }"
              data-toggle="dropdown"
              [matMenuTriggerFor]="cartSummaryMnu"
              ><span
                [matBadge]="CARTDATA.length"
                matBadgeSize="medium"
                matBadgePosition="top"
                ><img
                  width="30px"
                  style="-webkit-transform: scaleX(-1); transform: scaleX(-1)"
                  src="assets/images/shopping-cart.svg" /></span
              ><span class="caret"></span
            ></a>

            <!-- <span *ngIf='CARTDATA.length>0' class="dropdown-toggle nav-link" matTooltip='{{CARTDATA.length}} items in cart' style="display: none;"><span [matBadge]="CARTDATA.length" matBadgePosition="below" matBadgeSize="medium" (mouseenter)="showCartSummary(true)" (mouseleave)="showCartSummary(false)"><img width="30px" src="assets/images/shopping-cart.svg" [matMenuTriggerFor]="cartSummaryMnu"></span></span> -->
          </span>

          <mat-menu #cartSummaryMnu="matMenu">
            <div>
              <p style="text-align: center; font-weight: bold; padding: 10px">
                Cart Summary
              </p>
              <mat-divider></mat-divider>
              <mat-divider></mat-divider>
              <mat-card style="width: 220px">
                <div
                  class="d-flex justify-content-between"
                  style="font-weight: bold; color: gray"
                >
                  <div>Task Name</div>
                  <div>Task Cost</div>
                </div>
                <mat-divider></mat-divider>
                <div *ngFor="let item of CARTDATA; let id = index">
                  <div
                    class="col-12 d-flex justify-content-between"
                    style="padding: 4px 0"
                  >
                    <div class="col-7">
                      <span
                        >{{ id + 1 }}.
                        {{ item.taskname | slice: 0:10 }} ...</span
                      >
                    </div>
                    <div class="col-2">
                      <span
                        ><img
                          style="margin-left: 3px"
                          width="15px"
                          src="assets/images/info.svg"
                          matTooltipClass="my-tooltip"
                          matTooltip="{{ item.tooltip }}"
                      /></span>
                    </div>
                    <div
                      class="col-3 justify-content-end"
                      style="text-align: right"
                    >
                      <span style="text-align: right" class="div-txt"
                        >{{ currencySign
                        }}{{ item.taskcost | number: "1.0-0" }}</span
                      >
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </div>
                <div
                  class="col-12 align-self-center text-center"
                  style="margin: 10px 0 0 0"
                >
                  <a class="btn" routerLink="/tasks/cart">My Cart</a>
                  <!-- <mat-icon>dialpad</mat-icon>
                <span>Redial</span> -->
                </div>
              </mat-card>
            </div>
          </mat-menu>

          <li class="dropdown">
            <a
              *ngIf="!currentUser"
              class="dropdown-toggle nav-link"
              data-toggle="dropdown"
              [matMenuTriggerFor]="userMenu"
              ><span><span class="caret ml-5"></span></span
              ><mat-icon>account_circle</mat-icon></a
            >
            <a
              *ngIf="currentUser"
              class="dropdown-toggle nav-link"
              data-toggle="dropdown"
              [matMenuTriggerFor]="userMenu"
              ><img
                width="25px"
                height="25px"
                style="border-radius: 20px"
                src="{{ userPhoto }}" /><span class="caret"></span
            ></a>
            <mat-menu #userMenu="matMenu">
              <span *ngIf="!currentUser">
                <button
                  mat-menu-item
                  class="nav-link"
                  aria-current="page"
                  (click)="login()"
                >
                  Login
                </button>
                <button
                  mat-menu-item
                  class="nav-link"
                  aria-current="page"
                  (click)="register()"
                >
                  Register
                </button>
              </span>
              <span *ngIf="currentUser">
                <button
                  mat-menu-item
                  class="nav-link side-menu"
                  aria-current="page"
                  routerLink="/profile"
                >
                  My Profile
                </button>
                <mat-divider></mat-divider>
                <button
                  *ngIf="currentUser.userType === 'admin'"
                  mat-menu-item
                  class="nav-link side-menu"
                  aria-current="page"
                  routerLink="/admin/sensors"
                >
                  Sensors
                </button>
                <!-- <button mat-button [matMenuTriggerFor]="menu">Menu</button>
              <mat-menu #menu="matMenu">
                <button *ngIf="currentUser.userType==='admin'" mat-menu-item class="nav-link" aria-current="page" routerLink='/admin/sensors'>Sensors</button>
                <button mat-menu-item>Item 1</button>
                <button mat-menu-item>Item 2</button>
              </mat-menu> -->
                <mat-divider></mat-divider>
                <a
                  ><button
                    mat-menu-item
                    class="nav-link side-menu"
                    style="color: red"
                    (click)="logout()"
                  >
                    Logout
                  </button></a
                >
              </span>
            </mat-menu>
          </li>
        </ul>
        <!-- <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button> -->
      </form>
    </div>
  </div>
</nav>
