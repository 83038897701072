<div class="row">
  <div class="d-flex justify-content-center" style="padding: 0 40px">
    <div>
      <a class="navbar-brand" href="/home" title="Home"
        ><img width="120px" src="assets/images/logo.png"
      /></a>
    </div>
    <!-- <div>
        <a class="navbar-brand" routerLink='/help'>Help</a>
      </div> -->
  </div>
</div>
<div class="row">
  <div style="width: 100%">
    <div class="d-flex justify-content-center">
      <div>
        <img width="80px" src="assets/images/underconstruction.jpg" />
      </div>
    </div>
  </div>
</div>
