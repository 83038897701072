import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  currentUser: any;
  currentRoute: string;
  showFooter = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    // console.log(route.snapshot['_routerState'].url);
    // const id: string = route.snapshot.params.id;
    // const url: string = route.snapshot.url.join('');
    // const user = route.snapshot.data.user;
    // console.log(route.snapshot)
  }

  ngOnInit(): void {
    // console.log(this.route.snapshot['_routerState'].url);

    // this.route = this.router.url;
    // console.log(this.router.url);
    this.authService.getCurrentUser();
    this.authService.castCurrentUser.subscribe((res: any) => {
      this.currentUser = res;
      // console.log(this.currentUser)
    });
  }
}
