<div *ngIf="currentUser" class="row g-0">
  <span *ngIf="currentUser.name">
    <app-navbar></app-navbar>
  </span>
</div>
<div class="row g-0 body-content">
  <router-outlet></router-outlet>
</div>
<div *ngIf="showFooter">
  <div style="padding-top: 0px">
    <div *ngIf="currentUser; else loggedInFooter">
      <div *ngIf="currentUser.name; else defaultFooter">
        <app-footer></app-footer>
      </div>
      <ng-template
        #defaultFooter
        style="background: #d0f3f7 0% 0% no-repeat padding-box"
      >
        <app-footer></app-footer>
      </ng-template>
    </div>
    <ng-template
      #loggedInFooter
      style="background: #d0f3f7 0% 0% no-repeat padding-box"
    >
      <app-footer></app-footer>
    </ng-template>
  </div>
</div>
