<div class="row g-0 login-background">
  <div class="col-md-7 col-xs-7">
    <app-leftpan></app-leftpan>
  </div>
  <div class="col-5">
    <div class="row g-0">
      <div class="col-3"></div>
      <div class="{{ !notRegistered ? 'col-7 mt-100' : 'col-7 mt-20' }}">
        <div *ngIf="!success; else registerMsg" class="flex-container">
          <mat-card class="example-card">
            <p class="title" style="text-align: center; margin: 8px">Sign up</p>
            <form [formGroup]="userForm" class="mt-m-10">
              <div *ngIf="!notRegistered">
                <div class="mt-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Full Name</mat-label>
                    <input
                      class="input-upper"
                      matInput
                      required
                      placeholder="Name"
                      formControlName="name"
                      (keypress)="keyPressName($event)"
                    />
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error
                      *ngFor="let validation of validationMessages.name"
                    >
                      <mat-error
                        class="error-message"
                        *ngIf="
                          userForm.get('name').hasError(validation.type) &&
                          (userForm.get('name').dirty ||
                            userForm.get('name').touched)
                        "
                      >
                        {{ validation.message }}</mat-error
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mt-15">
                  <mat-form-field appearance="outline">
                    <mat-label class="mat-label">Name for display</mat-label>
                    <input
                      class="input-upper"
                      matInput
                      required
                      placeholder="Display Name"
                      formControlName="displayname"
                    />
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error
                      *ngFor="let validation of validationMessages.displayname"
                    >
                      <mat-error
                        class="error-message"
                        *ngIf="
                          userForm
                            .get('displayname')
                            .hasError(validation.type) &&
                          (userForm.get('displayname').dirty ||
                            userForm.get('displayname').touched)
                        "
                      >
                        {{ validation.message }}</mat-error
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </form>
            <form *ngIf="!notRegistered" [formGroup]="userEmailForm">
              <div class="mt-15">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label class="mat-label">Email</mat-label>
                  <input
                    matInput
                    (change)="validEmail()"
                    placeholder="Your Email"
                    formControlName="email"
                    required
                  />
                  <mat-icon matSuffix>email</mat-icon>
                  <mat-error
                    *ngFor="let validation of validationMessages.email"
                  >
                    <mat-error
                      class="error-message"
                      *ngIf="
                        userEmailForm.get('email').hasError(validation.type) &&
                        (userEmailForm.get('email').dirty ||
                          userEmailForm.get('email').touched)
                      "
                    >
                      {{ validation.message }}</mat-error
                    >
                  </mat-error>
                  <mat-hint class="warn">{{ warnMsg }}</mat-hint>
                </mat-form-field>
              </div>
              <div class="d-flex justify-content-center" style="margin: 10px 0">
                <span class="w-100 mt-30"
                  ><button
                    class="btn"
                    (click)="chkUser()"
                    [disabled]="
                      userEmailForm.invalid ||
                      userForm.get('displayname').invalid ||
                      userForm.get('name').invalid
                    "
                  >
                    Next
                  </button></span
                >
                <!-- <button mat-dialog-close class="btn fitwidth" style="width: 100px;">Close</button> -->
              </div>
            </form>
            <form [formGroup]="userForm" class="mt-m-10">
              <div *ngIf="notRegistered">
                <mat-label style="margin: 8px; text-align: center"
                  >Your Email:</mat-label
                >
                {{ this.userEmailForm.value.email.toLowerCase() }}
                <!-- <div class="mt-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Full Name</mat-label>
                    <input class="input-upper" matInput required placeholder="Name" formControlName='name'
                      (keypress)='keyPressName($event)'>
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error *ngFor="let validation of validationMessages.name">
                      <mat-error class="error-message"
                        *ngIf="userForm.get('name').hasError(validation.type) && (userForm.get('name').dirty || userForm.get('name').touched)">
                        {{validation.message}}</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mt-12">
                  <mat-form-field appearance="outline">
                    <mat-label class="mat-label">Name for display</mat-label>
                    <input class="input-upper" matInput required placeholder="Display Name"
                      formControlName='displayname'>
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error *ngFor="let validation of validationMessages.displayname">
                      <mat-error class="error-message"
                        *ngIf="userForm.get('displayname').hasError(validation.type) && (userForm.get('displayname').dirty || userForm.get('displayname').touched)">
                        {{validation.message}}</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div> -->
                <div class="mt-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Your Company Name</mat-label>
                    <input
                      class="input-upper"
                      matInput
                      required
                      placeholder="Company Name"
                      formControlName="company"
                      (keypress)="keyPressCompany($event)"
                    />
                    <!-- <mat-icon matSuffix>face</mat-icon> -->
                    <mat-error
                      *ngFor="let validation of validationMessages.company"
                    >
                      <mat-error
                        class="error-message"
                        *ngIf="
                          userForm.get('company').hasError(validation.type) &&
                          (userForm.get('company').dirty ||
                            userForm.get('company').touched)
                        "
                      >
                        {{ validation.message }}</mat-error
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mt-12">
                  <mat-form-field appearance="outline">
                    <mat-label class="mat-label">Phone number</mat-label>
                    <mat-icon matSuffix>phone</mat-icon>
                    <input
                      matInput
                      placeholder="Mobile number"
                      formControlName="phone"
                      (change)="chngPhone()"
                      (keypress)="keyPress($event)"
                    />
                    <mat-error
                      *ngFor="let validation of validationMessages.phone"
                    >
                      <mat-error
                        class="error-message"
                        *ngIf="
                          userForm.get('phone').hasError(validation.type) &&
                          (userForm.get('phone').dirty ||
                            userForm.get('phone').touched)
                        "
                      >
                        {{ validation.message }}</mat-error
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mt-12">
                  <mat-form-field appearance="outline">
                    <mat-label class="mat-label">Password</mat-label>
                    <input
                      matInput
                      placeholder="Password"
                      (keyup)="pasType($event)"
                      [type]="hide ? 'password' : 'text'"
                      formControlName="password"
                      required
                    />
                    <mat-icon matSuffix (click)="hide = !hide">{{
                      hide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                    <!-- <mat-hint>Enter password </mat-hint> -->
                    <mat-error
                      *ngFor="let validation of validationMessages.password"
                    >
                      <mat-error
                        class="error-message"
                        *ngIf="
                          userForm.get('password').hasError(validation.type) &&
                          (userForm.get('password').dirty ||
                            userForm.get('password').touched)
                        "
                      >
                        {{ validation.message }}</mat-error
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mt-12">
                  <mat-form-field appearance="outline">
                    <mat-label class="mat-label">Confirm Password</mat-label>
                    <input
                      matInput
                      required
                      (keyup)="areEqual($event)"
                      formControlName="confirmpassword"
                      placeholder="Confirm Password"
                      [type]="hides ? 'password' : 'text'"
                      appBlockCopyPaste
                    />
                    <mat-icon matSuffix (click)="hides = !hides">{{
                      hides ? "visibility_off" : "visibility"
                    }}</mat-icon>
                    <!-- <mat-hint *ngIf='pswrdMatched'>Repeat password </mat-hint> -->
                    <mat-hint *ngIf="!pswrdMatched" style="color: red"
                      >Password mismatched
                    </mat-hint>
                    <mat-hint *ngIf="!pswrdNull" style="color: red"
                      >Please enter the password
                    </mat-hint>
                    <mat-error
                      *ngFor="
                        let validation of validationMessages.confirmpassword
                      "
                    >
                      <mat-error
                        class="error-message"
                        *ngIf="
                          userForm
                            .get('confirmpassword')
                            .hasError(validation.type) &&
                          (userForm.get('confirmpassword').dirty ||
                            userForm.get('confirmpassword').touched)
                        "
                      >
                        {{ validation.message }}</mat-error
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row g-0 mt-m-10">
                  <div class="d-flex justify-content-between">
                    <div
                      class="col-10"
                      style="padding-left: 10px; padding-top: 8px"
                    >
                      <span
                        ><input
                          style="display: none"
                          type="file"
                          accept="image/*"
                          formControlName="photo"
                          (change)="onFileSelected($event)"
                          #imgInputs
                        />
                        <img *ngIf="imgUrl" [src]="imgUrl" width="30" />
                      </span>
                      <span
                        *ngIf="oversize"
                        style="color: red; text-align: left; font-size: 11px"
                        >File size must be less than 20 Kb</span
                      >
                      <span
                        *ngIf="imageType"
                        style="color: red; text-align: left; font-size: 11px"
                      >
                        Selected file is not an image type
                      </span>
                    </div>
                    <div class="col-2">
                      <span
                        class="align-self-center"
                        (click)="imgInputs.click()"
                        ><img
                          matTooltip="Upload your profile photo"
                          width="30"
                          src="assets/images/photoupload.png"
                      /></span>
                    </div>
                  </div>
                </div>

                <div class="btnspacing pt-2">
                  <button
                    class="btn"
                    color="primary"
                    [disabled]="
                      !userForm.valid ||
                      oversize ||
                      imageType ||
                      !pswrdMatched ||
                      !pswrdNull
                    "
                    mat-raised-button
                    (click)="onCloseConfirm()"
                    (keydown)="submitForm($event)"
                  >
                    Register
                  </button>
                </div>
              </div>
            </form>
          </mat-card>
          <label
            class="link-primary link-primary-font txt-algin-left"
            style="margin-bottom: 30px"
            >Already registered,
            <span (click)="openDialogLogin()" class="sign-up"
              >Login</span
            ></label
          >

          <!-- <div style="display:block; text-align: center; margin-top: 8px; font-size: small;">Already have an account? <span (click)="openDialogLogin()" style='cursor: pointer; color: blue;'>Login</span></div> -->
        </div>
        <ng-template #registerMsg>
          <mat-card class="mt-100">
            <div class="text-center">
              <img width="35px" src="assets/images/success-mark.svg" alt="" />
              <!-- <img style="width:30px;" src="/assets/images/thumbs-up-green.svg"> -->
              <p style="margin: 10px">{{ warnMsg }}</p>
              <!-- <label for="activationcode">Your activation code:</label>
              <input #activationcode type="text"> -->
              <mat-divider></mat-divider>
              <div
                (click)="openDialogLogin()"
                style="cursor: pointer; color: blue; margin: 15px"
              >
                Login Now
              </div>
            </div>
          </mat-card>
        </ng-template>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</div>
