<div class="margin-head">
  <p class="login-text">Welcome to</p>
  <span class="m-40"
    ><img class="img-width" src="assets/images/logo.png"
  /></span>
  <p class="login-text">Discover the Earth with us</p>
</div>
<div class="margin-Image">
  <div><img class="sliderimg-width" src="assets/images/leftpan.png" /></div>
</div>
