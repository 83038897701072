import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserloginComponent } from '../components/userlogin/user-login.component';
// import { UserloginComponent1 } from '../components/userlogin_bak/user-login.component';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';
import { CommonService } from './common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  currentUser: any;
  url = '';
  isAdmin = false;
  isVendor = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private commonSer: CommonService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.url = state.url;
    // const cart = this.router.getCurrentNavigation()!.extras.state;
    // let type = route.data as Array<string>;
    // route.getCurrentNavigation().extras.state;
    // console.log(state.url, this.router.getCurrentNavigation().extras.state);
    if (this.isTokenExpired() === false) {
      // console.log(state.url);
      // this.router.navigate(['login', { url: state.url }]);
      return true;
      //   if (this.isVendor === true) {
      //     if (this.url === '/admin/createproduct' || this.url === '/admin/products') {
      //       return true;
      //     } else if (this.isAdmin === true && this.url === '/admin/users') {
      //       return true;
      //     }
      //   // } else {
      //     // return true;
      //   }
    }
    let returnUrl = state.url;
    // if (state.url.length > 0) {
    returnUrl = state.url;
    // } else {
    //   returnUrl = 'tasks/mytasks';
    // }
    // this.dialog.open(UserloginComponent1, { data: { url: returnUrl, cart }});
    this.router.navigate(['login', { url: returnUrl }]);
    return false;
  }

  isTokenExpired(): boolean {
    try {
      const LoginTokenValue = sessionStorage.getItem(this.commonSer.JWT_TOKEN);
      if (LoginTokenValue) {
        return false;
        // const { exp } = new JwtHelperService().decodeToken(LoginTokenValue) || '';

        // const currentTime = (new Date().getTime() + 1) / 1000;
        // if (exp > currentTime) {
        //   this.authService.castcurrentUser.subscribe(res => {
        //   this.currentUser = res;
        //   if (this.currentUser) {
        //     if (this.currentUser.userType === 'admin' || this.currentUser.userType === 'super-admin') {
        //       this.isAdmin = true;
        //     }
        //     if (this.isAdmin || this.currentUser.userType === 'vendor' || this.currentUser.userType === 'prime-vendor') {
        //       this.isVendor = true;
        //     }
        //   }
        //   // // console.log(res);
        //   });
        //     // this.handleSetTimeout();
        //     // this.as.refreshToken();
        //     // console.log(-currentTime + exp);
        //   return false;
        //   } else {
        //       // this.as.refreshToken();
        //       this.authService.logout();
        //       // sessionStorage.clear();
        //       // console.log('Session expired.');
        //       this.router.navigate(['/']);
        //       // Navigate inside the application
        //       return true;
        //   }
      } else {
        // Navigate to the login page
        return true;
      }
    } catch (err) {
      console.log('Spalsh -> isTokenExpired -> err', err);
      // Navigate to the login page
      return true;
    }
  }
}
