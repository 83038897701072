import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../services/user.service';
// import { UserModel } from '../models/product.model';
// import { CartService } from './cart.service';
import { UserModel } from '../models/api.model';
import { Token } from '../models/token.model';
import { CommonService } from './common.service';
import { environment } from '../../environments/environment.prod';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUser: UserModel = undefined;
  public currentUserBS = new BehaviorSubject<UserModel>(this.currentUser);
  // private isRefreshing = false;
  // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  castCurrentUser = this.currentUserBS.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private us: UserService,
    private commonSer: CommonService
  ) {}

  public getCurrentUser(): UserModel {
    // console.log(environment.eeTaskServer);
    // this.castCurrentUser = this.currentUserBS.asObservable();
    let retVal: any;
    let tokenStr: any = sessionStorage.getItem(this.commonSer.JWT_TOKEN)!;
    if (tokenStr) {
      // const decodedToken: any = tokenStr;;
      if (tokenStr.includes('Msg')) return tokenStr;
      const decodedToken: any = new JwtHelperService().decodeToken(tokenStr);
      // console.log(decodedToken);
      if (decodedToken) {
        this.currentUserBS.next(decodedToken);
        retVal = decodedToken;
      }
    }
    return retVal;
  }
  public loginwithNumber(phone: string, password: string): any {
    // phoneNumber = phoneNumber.toLowerCase();
    this.us.getUserWithPhonenPswrd(phone, password).subscribe(
      (result: Token | any) => {
        // console.log(result.status, result)
        if (result.Msg) {
          this.currentUserBS.next(result);
        } else {
          this.saveToken(result);
          this.getCurrentUser();
        }
        // this.cs.saveCart2Server();
        // console.log(true);
        return true;
      },
      (err) => {
        const errMsg: any = { status: 0 };
        this.currentUserBS.next(errMsg);
        // console.log(err.status);
        // const data: any = {err: err.error}
        // return data;
      }
    );
  }
  public loginwithEmail(loginparams: string): any {
    // const user: any = {name: 'Rama'};
    // email = email.toLowerCase();
    this.us.getUserWithEmailnPswrd(loginparams).subscribe(
      (result: any) => {
        // if (result.Msg !== 'User not registered')
        // console.log(result)
        this.saveToken(result);
        this.currentUserBS.next(result);
        this.getCurrentUser();
        // this.cs.saveCart2Server();
        // console.log(true);
        return true;
      },
      (err) => {
        this.currentUserBS.next(err.error);
        // err.console.error();
        console.log('Wrong credential to login');
        return false;
      }
    );
    // this.currentUserBS.next(undefined);

    // return false;
    // }
  }
  broadCastUser(user: any) {
    this.currentUserBS.next(this.currentUser);
    // console.log(user);
  }
  logout() {
    // remove user from session storage to log user out
    sessionStorage.removeItem(this.commonSer.JWT_TOKEN);
    this.currentUser = undefined;
    // sessionStorage.removeItem(this.commonSer.REFRESH_TOKEN);
    this.currentUserBS.next(this.currentUser);
    // this.getCurrentUser();
    this.router.navigate(['/login']);
  }

  saveToken(token: any) {
    // console.log(token)
    if (token.Msg === 'User not registered' || token.Msg === 'Wrong password') {
      this.currentUser = undefined;
      // sessionStorage.removeItem(this.commonSer.REFRESH_TOKEN);
      this.currentUserBS.next(this.currentUser);
      this.getCurrentUser();
      // console.log('hello')
      sessionStorage.setItem(this.commonSer.JWT_TOKEN, JSON.stringify(token));
    } else {
      // console.log('hello2')
      // sessionStorage.removeItem(this.JWT_TOKEN);
      sessionStorage.setItem(
        this.commonSer.JWT_TOKEN,
        JSON.stringify(token.jwt)
      );
      // sessionStorage.setItem(this.commonSer.REFRESH_TOKEN, JSON.stringify(token.refreshToken));
    }
  }
}
