import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ValidationService } from '../../services/validation.service';
import { AuthService } from '../../services/auth.service';
import { UserregisterComponent } from '../userregister/userregister.component';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'user-login',
  templateUrl: 'user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserloginComponent {
  name = '';
  email = '';
  password = '';
  option = '';
  content = '';
  currentUser: any;
  cart: any;
  returnUrl = '';
  hide = true;
  loading = true;
  loginForm: FormGroup | any;
  validationMessages: any;
  phoneNum: any;
  // loginForm: FormGroup = new FormGroup({
  //   tel: new FormControl(new MyTel('', '', '')),
  //   password: new FormControl()
  // });
  warnMsg: any;
  validPhone = false;
  formGroup: FormGroup;
  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private vs: ValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private commonSer: CommonService
  ) {}

  ngOnInit() {
    this.authService.getCurrentUser();
    this.authService.castCurrentUser.subscribe((res: any) => {
      this.currentUser = res;
      // console.log(this.currentUser)
    });
    sessionStorage.removeItem(this.commonSer.JWT_TOKEN);
    this.formGroup = new FormGroup({
      // nameField: new FormControl(this.name, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      passwordField: new FormControl(this.password, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
      ]),
      emailField: new FormControl(this.email, [
        Validators.required,
        Validators.email,
      ]),
    });

    this.loginForm = this.fb.group({
      //   phone: ['', [ Validators.required,
      //     Validators.pattern("^[0-9]*$"),
      //     Validators.minLength(10), Validators.maxLength(10)]],
      password: ['', Validators.compose([Validators.required])],
    });
    this.validationMessages = this.vs.account_validation_messages;
    const url = this.route.snapshot.params['url'];
    if (url) {
      this.returnUrl = url;
    } else this.returnUrl = 'tasks/mytasks';
  }
  submitForm(event: any) {
    // console.log(event.keyCode)
    if (event.keyCode === 13) {
      this.onCloseConfirm();
    }
  }
  async onCloseConfirm() {
    const userEmail = this.formGroup.get('emailField').value.toLowerCase(); // to mail
    const pswrd = this.formGroup.get('passwordField').value;

    const loginparams = encodeURIComponent(
      JSON.stringify({ email: userEmail, password: pswrd })
    );
    this.authService.loginwithEmail(loginparams);
    this.authService.castCurrentUser.subscribe(
      async (res: any) => {
        this.currentUser = await res;
        // console.log(res)
        if (res)
          if (res.Msg) {
            if (res.Msg === 'User not registered') {
              this.warnMsg = 'User with this email not registered';
            } else if (res.Msg === 'Wrong password') {
              this.warnMsg = 'Wrong password entered';
            }
            this.onCloseCancel();
          } else {
            // console.log(this.returnUrl)
            this.router.navigate([this.returnUrl]);
          }
      },
      (err) => {
        this.warnMsg = 'Something went wrong, try later.';
        // console.log('Something went wrong, try later.');
      }
    );
  }
  onCloseCancel() {
    //  this.thisDialogRef.close('Cancel');
  }

  openDialogRegister() {
    this.router.navigate(['register']);

    // this.thisDialogRef.close('Cancel');
    // this.dialog.open(UserregisterComponent);
  }

  getErrorMessage(control: AbstractControl): string {
    // Don't say anything if control doesn't exist, or is valid
    if (!control || control.valid) {
      return '';
    }
    // Required always comes first
    if (control.hasError('required')) {
      return 'Email is required';
    }
    if (control.hasError('email')) {
      return 'Must be a valid email';
    }
    if (control.hasError('minlength')) {
      const limit = control.getError('minlength').requiredLength;
      return `Must be at least ${limit} characters`;
    }
    if (control.hasError('minlength')) {
      const limit = control.getError('maxlength').requiredLength;
      return `Must be no more than ${limit} characters`;
    }
    // Default general error message
    return 'Invalid input';
  }

  getErrorMessages(control: AbstractControl): string {
    // Don't say anything if control doesn't exist, or is valid
    if (!control || control.valid) {
      return '';
    }
    // Required always comes first
    if (control.hasError('required')) {
      return 'Password is required';
    }
    if (control.hasError('minlength')) {
      const limit = control.getError('minlength').requiredLength;
      return `Must be at least ${limit} characters`;
    }
    if (control.hasError('minlength')) {
      const limit = control.getError('maxlength').requiredLength;
      return `Must be no more than ${limit} characters`;
    }
    // Default general error message
    return 'Invalid input';
  }
  // async onSubmit() {
  //     const url = location.origin;
  //     // const fromMailId = 'Info - RSM<info@risingsunmart.com>'; // from mail
  //     const fromMailId = '';// 'Rama<rksingh_rk@yahoo.com>'; // from mail
  //     // const userName = this.formGroup.get('nameField').value;
  //     const userEmail = this.formGroup.get('emailField').value; // to mail
  //     const pswrd = this.formGroup.get('passwordField').value;

  //     // const sub = 'Feedback',
  //     // emailbody = `<p>Hello <strong>${userName}</strong>,</p><div>Thank you for reaching us.</div><br><div>We will get back to you within 1 business day.</div><br><div class='word-wrap: break-word;'><strong>Your message: </strong>${content}</div><br><div>Regards,</div><div>Support team, Earth Eye</div><div>Website: <a href=${location.origin} target='_blank'>${location.origin}</a></div><div>Have question? <a href=${location.href} target='_blank'>Any question please feel free to get in touch with us.</a></div>`;
  //     // const mailRes = await fetch(`${this.apiService.MailServiceUrl}/sendmail?fromEmail=${fromMailId}&toEmail=${userEmail}&subject=${sub}&emailbody=${emailbody}`);

  //     // const myVar = {fromEmail: fromMailId, userEmail: userEmail, subject: sub, emailbody: emailbody};

  //     // fetch(`${this.apiService.MailServiceUrl}/sendmail`, {
  //     //     method: "POST",
  //     //     headers: {
  //     //         "Content-Type": "text/plain"
  //     //     },
  //     //     body: JSON.stringify(myVar)
  //     // }).then(function(response) {
  //     //     return response.json();
  //     // }).then(function(res){
  //     //     console.log(res)
  //     //     // document.getElementById('väljund').innerHTML = JSON.stringify(muutuja);
  //     // });

  //     // console.log(userEmail, emailbody);
  //   this.submitted = true;
  //   // this.loading = false;
  //   // this.formGroup.reset();
  //   console.log(this.returnUrl, pswrd, userEmail )
  //   this.onCloseCancel();
  //   // this.returnUrl = 'home';

  //   if (this.returnUrl) {
  //     this.router.navigate([this.returnUrl]);
  //     // this.router.navigate([this.returnUrl], { state: this.cart });
  //   }
  // }

  forgotPswrd() {
    const userEmail = this.formGroup.get('emailField').value;
    // console.log(userEmail);
    if (this.warnMsg.includes('Wrong')) {
      this.userService.forgotPassword(userEmail).subscribe(async (res: any) => {
        this.warnMsg = res;
        // console.log(res);
        // this.currentUser = await res;
      });
    }
  }
  // async sendAutoResponse() {
  //     const url = location.origin;
  //     console.log(url)
  //     const userName = this.formGroup.get('nameField').value;
  //     const userEmail = this.formGroup.get('emailField').value;
  //     const content = this.formGroup.get('contentField').value;
  //     const sub = 'Feedback',
  //     emailbody = `<p>Hello <strong>${userName}</strong>,</p><div>Thank you for reaching us.</div><br><div>We will get back to you within 1 business day.</div><br><div class='word-wrap: break-word;'>${content}</div><br><div>Regards,</div><div>Support team, Earth Eye</div><div>Website: <a href=${location.origin} target='_blank'>${location.origin}</a></div><div>Have question? <a href=${location.href} target='_blank'>Any question please feel free to get in touch with us.</a></div>`;
  //     const mailRes = await fetch(`${this.apiService.MailServiceUrl}/sendmail?email=${userEmail}&subject=${sub}&emailbody=${emailbody}`);
  //     // console.log(userEmail, emailbody);
  //   this.submitted = true;
  //   this.formGroup.reset();
  // }
  // get nameField(): AbstractControl {
  //   return this.formGroup.get('nameField');
  // }
  get emailField(): AbstractControl {
    return this.formGroup.get('emailField');
  }
  get passwordField(): AbstractControl {
    return this.formGroup.get('passwordField');
  }

  // get contentField(): AbstractControl {
  //   return this.formGroup.get('contentField');
  // }
}
