<h1 mat-dialog-title>Hi {{data.name}},</h1>
<div>
  <div mat-dialog-content style="text-align: center">
    <p>Are you sure to logout?</p>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button (click)="onNoClick()" class="btn">No Thanks</button>
    <button
      mat-button
      [mat-dialog-close]="data.confirm"
      cdkFocusInitial
      class="btn"
    >
      <span> Yes</span>
    </button>
  </div>
</div>
