import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @ViewChildren('footerMenu') footerMenu!: QueryList<any>;
  @ViewChild('footer') footer!: ElementRef;
  title = this.apiService.title;
  contactMail = this.apiService.contactMail;
  website = location.origin;
  showDetail = false;
  curYear = new Date().getFullYear();
  curYearStr = this.curYear.toString().slice(2, 4);
  mailTo = '';
  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // console.log(this.curYear)
    this.mailTo = 'mailto:' + this.contactMail;
    // this.curYear =  new Date().getFullYear().toString();
  }

  gotoRoute(url: string) {
    // console.log(url);
    this.router.navigate([url]);
  }
  ngAfterViewInit() {
    this.scrollWin();
    this.footerMenu.changes.subscribe(this.scrollWin);
  }
  scrollWin() {
    // console.log(this.showDetail, document.body.scrollHeight)
    try {
      this.footer.nativeElement.scrollTop =
        this.footer.nativeElement.scrollHeight;
    } catch (err) {}
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
