import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
// import { Title } from '@angular/platform-browser';
// import { Lang } from '../../assets/i18n/lang';
// import { Subject } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })

@Injectable()
export class CommonService {
  title = 'Earth Eye';
  // commission = 0.05; // 5% as brokerage i.e %age divided by 100
  // server = environment.accessServer;
  // readonly productsImgUrl: string = this.server + '/assets/images/products/';
  // readonly imgUrl = environment.usersServer + '/assets/images/';
  priceSlider = { floor: 100, ceil: 5000, min: 1500, max: 2500 };

  itemsPerPage = 10;
  shippingRate = 96; //with per half kg Rs.48
  // readonly currencySym = '₹';

  readonly otherServices = [
    {
      name: 'Tiffin Service',
      link: '/#/other-services/tiffin',
      image: '/assets/images/tiffin.png',
    },
    {
      name: 'Blogs',
      link: 'https://blogs.freegyanhub.com',
      image: '/assets/images/blog-icon.png',
    },
    {
      name: 'Profiles',
      link: 'https://profiles.freegyanhub.com',
      image: '/assets/images/ads/profile.png',
    },
  ];
  readonly navList = [
    {
      title: 'Home',
      image: '',
      icon: 'home',
      tooltip: 'Home',
      menu: '',
      link: '/home',
    },
    {
      title: 'Shop',
      image: '',
      icon: 'shop',
      tooltip: 'Shop',
      menu: '',
      link: '/stores',
    },
    // {title: 'Location', image:'/assets/icons/map-icon.svg', icon:'', tooltip:'Location wise product available', menu:'location', link:''},
    {
      title: 'Catalog',
      image: '/assets/images/catalog/catalog.svg',
      tooltip: 'Catalog',
      menu: 'catalog',
      link: '',
    },
    {
      title: 'Theme',
      image: '/assets/images/theme.svg',
      tooltip: 'Theme',
      menu: 'theme',
      link: '',
    },
    {
      title: 'Language',
      image: '',
      icon: 'language',
      tooltip: 'Language',
      menu: 'lang',
      link: '',
    },
    {
      title: 'Contact Us',
      image: '',
      icon: 'contact_mail',
      tooltip: 'Contact Us',
      menu: '',
      link: 'contact-us',
    },
    {
      title: 'About',
      image: '/assets/icons/info-sign.png',
      icon: '',
      tooltip: 'About Us',
      menu: '',
      link: 'about-us',
    },
  ];

  readonly adsSlides = [
    {
      name: 'Marketplace',
      description: 'Place your products with us !',
      link: '/#/contact-us',
      target: '',
      image: '/assets/images/ads/buynsale.png',
    },
    {
      name: 'Create Your Profile',
      description: 'Your own profile with few clicks',
      link: 'https://profiles.freegyanhub.com',
      target: '_blank',
      image: '/assets/images/ads/profile.png',
    },
    {
      name: 'Interested in Blogs?',
      description: 'Read and write blogs here...',
      link: 'https://blogs.freegyanhub.com',
      target: '_blank',
      image: '/assets/images/ads/blog.png',
    },
    {
      name: 'Web development, application and more...',
      description: 'Get ready your website',
      link: '/#/contact-us',
      target: '',
      image: '/assets/images/ads/webdevelop.png',
    },
  ];

  // readonly categories = [
  //     {name: 'She', description: 'For Women', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/she.jpg'},
  //     {name: 'He', description: 'For Men', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/he.jpg'},
  //     {name: 'Clothes', description: 'Clothes', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/clothes.jpg'},
  //     {name: 'Sarees', description: 'Sarees', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/sarees.webp'},
  //     {name: 'T-Shirts', description: 'T-Shirts', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/t-shirts.png'},
  //     {name: 'Leggings', description: 'Leggings', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/leggings.jpg'},
  //     {name: 'Bedsheets', description: 'Bedsheets', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/bedsheets.png'},
  //     {name: 'Stoles', description: 'Ladies Stoles', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/stoles.jpg'},
  //     {name: 'Pants', description: 'Pants', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/pants.jpg'},
  //     {name: 'Clothes', description: 'Clothes', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/clothes.png'},
  //     {name: 'Clothes', description: 'Clothes', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/clothes.png'},
  //     {name: 'Clothes', description: 'Clothes', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/clothes.png'},
  //     {name: 'Clothes', description: 'Clothes', link:'#/stores;category=Clothes', target:'', image:'/assets/images/catalog/clothes.png'},
  //   ];
  readonly foodSlides = [
    {
      name: 'Slide0',
      link: '/#/tiffin',
      image: '/assets/images/food/images0.jpg',
    },
    {
      name: 'Slide1',
      link: '/#/tiffin',
      image: '/assets/images/food/images1.jpg',
    },
    {
      name: 'Slide2',
      link: '/#/tiffin',
      image: '/assets/images/food/images2.jpg',
    },
    {
      name: 'Slide3',
      link: '/#/tiffin',
      image: '/assets/images/food/images3.jpg',
    },
    {
      name: 'Slide4',
      link: '/#/tiffin',
      image: '/assets/images/food/images4.jpg',
    },
    {
      name: 'Slide5',
      link: '/#/tiffin',
      image: '/assets/images/food/images5.jpg',
    },
    {
      name: 'Slide6',
      link: '/#/tiffin',
      image: '/assets/images/food/images6.jpg',
    },
  ];
  readonly allColors = [
    'White',
    'Yellow',
    'Black',
    'Red',
    'Blue',
    'Green',
    'Maroon',
    'Orange',
    'Violet',
    'Pink',
    'Purple',
    'Lime',
  ];
  readonly allLocations = [
    'Mira Road',
    'Virar',
    'Borivali',
    'Dahisar',
    'Varanasi',
    'Sasaram',
    'Lucknow',
  ];
  // readonly allLocations = ["Mumbai(Mira Road, Virar, 'Borivali, Dahisar)", 'Varanasi', 'Sasaram', 'Lucknow'];
  readonly sizeList: Array<any> = [
    {
      type: 'Others',
      values: [
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
      ],
    },
    {
      type: 'Clothes',
      values: ['XXXL', 'XXL', 'XL', 'L', 'M', 'S', 'XS', 'XXS'],
    },
    {
      type: 'Weight',
      values: ['1/2 kg', '1 kg', '2 kg', '5 Kg', '10 kg', '20 kg'],
    },
    { type: 'Number', values: ['3', '4', '5', '6', '7', '8'] },
    { type: 'Inch', values: ['15 inch', '20 inch', '25 inch'] },
  ];

  readonly deliveryMethods = [
    // { value: 'free', name: 'Free Delivery', cost: 0 , desc: 'Delivery in 7 to 14 business Days', enable: false },
    // { value: 'standard', name: 'Standard Delivery', cost: 50, desc: 'Delivery in 5 to 7 business Days', enable: true },
    // { value: 'express', name: 'Express Delivery', cost: 100, desc: 'Delivery in 1 business Day', enable: true }
    {
      value: 'free',
      name: 'Free Delivery',
      cost: 0,
      desc: 'Normal delivery',
      enable: true,
    },
    {
      value: 'standard',
      name: 'Standard Delivery',
      cost: 50,
      desc: ' Express delivery 2 to 3 business day',
      enable: false,
    },
    {
      value: 'express',
      name: 'Express Delivery',
      cost: 100,
      desc: 'Express delivery in 1 business Day',
      enable: false,
    },
  ];

  // readonly payOptions:  'Pay Now' | 'Pay on Delivery' | 'Cash on Delivery' = 'Pay on Delivery';
  readonly payOptions = [
    { option: 'Pay Now', enable: true },
    { option: 'Pay on Delivery', enable: true },
    { option: 'Cash on Delivery', enable: false },
  ];

  readonly userTypes = [
    'super-admin',
    'admin',
    'prime-vendor',
    'vendor',
    'prime-customer',
    'customer',
  ];
  readonly orderStatuses = [
    'Pending with Seller',
    'Cancelled by Seller',
    'Accepted the order',
    'In packaging',
    'Dispatched',
    'Out for delivery',
    'Delivered',
  ];
  readonly JWT_TOKEN = 'jwttoken';
  readonly productProperties = ['Size', 'Color'];
  // readonly REFRESH_TOKEN = 'refreshtoken';

  readonly TIMEOUTTIME = 30; // countdown 30 seconds
  readonly IDLETIME = 3600; // wait for 60 minutes
  readonly KEEPALIVETIME = 1; // show for 1 seconds
  data: any = [];
  my_form: any;

  constructor(private http: HttpClient) {}
  // getClientFingerPrint(url: string): any {
  //   return this.http.get(`${this.uri}/home/${url}`).subscribe();
  // }

  // getAll(route: string): any {
  //   const req = this
  //               .http
  //               .get(`${this.server}/${route}/all`);
  //               // .subscribe( (data) => console.log(JSON.stringify(data)));
  //   return req;
  // }

  readonly paytm = {
    payurl: 'https://securegw-stage.paytm.in/theia/processTransaction', // for staging/testing payment
    // paynowUrl: this.server + '/home/pay-stage',                            // for staging/testing payment

    // payurl: 'https://securegw.paytm.in/order/process', // for production/real payment
    // paynowUrl: this.server + '/home/paynow',              // for production/real payment

    vId: '5fde13a017da232ca1d27192',
    // MID: "NpGAsN11649496459796", // paytm provide
    //   WEBSITE: "WEBSTAGING", // paytm provide
    //   INDUSTRY_TYPE_ID: "Retail", // paytm provide
    //   CHANNEL_ID: "WEB", // paytm provide
    // CALLBACK_URL: "http://localhost:4200/#/stores/checkout/order-status", // Call back URL that i want to redirect after payment fail or success
  };

  payNow(orderObj: any): any {
    // return this.http.post(`${this.paytm.paynowUrl}`, orderObj)
    // .subscribe((res: any) =>{
    //   this.createPaytmForm(res.paytm);
    // });
  }

  createPaytmForm(params: any) {
    this.my_form = document.createElement('form');
    this.my_form.name = 'paytm_form';
    this.my_form.method = 'post';
    this.my_form.action = this.paytm.payurl;

    const myParams = Object.keys(params);
    for (let i = 0; i < myParams.length; i++) {
      const key = myParams[i];
      let my_tb: any = document.createElement('input');
      my_tb.type = 'hidden';
      my_tb.name = key;
      my_tb.value = params[key];
      this.my_form.appendChild(my_tb);
      //  console.log(my_tb)
    }
    document.body.appendChild(this.my_form);
    this.my_form.submit();

    // after click will fire you will redirect to paytm payment page.
    // after complete or fail transaction you will redirect to your CALLBACK URL
  }
}
