<div
  class="row g-0"
  style="position: relative; background: #d0f3f7; height: calc(100vh - 42px)"
>
  <div class="col-md-7 col-xs-7">
    <app-leftpan></app-leftpan>
  </div>
  <div class="col-md-5 col-xs-5" style="text-align: center; margin-top: 100px">
    <p style="font-family: cursive; font-size: x-large">Privacy Policy</p>
    EarthEye is a Multi-tasking Platform
    <!-- <img width="80px" src="assets/images/underconstruction.jpg"> -->
  </div>
</div>
